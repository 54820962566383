class Endorsement {
  constructor({
    id = 0,
    candidateId = 0,
    userId = null,
    firstName = null,
    middleName = null,
    lastName = null,
    email = null,
    statement = null,
    isApproved = false,
    last4DigitSsnOrMemberId = null,
    endorsementStatementIds = [],
    position = null,
    dateCreated = null,
    dateSubmitted = null,
    clientIpAddress = null,
    status = null,
  } = {}) {
    this.id = id;
    this.candidateId = candidateId;
    this.userId = userId;
    this.firstName = firstName;
    this.middleName = middleName;
    this.lastName = lastName;
    this.email = email;
    this.statement = statement;
    this.isApproved = isApproved;
    this.last4DigitSsnOrMemberId = last4DigitSsnOrMemberId;
    this.endorsementStatementIds = endorsementStatementIds;
    this.position = position;
    this.dateCreated = dateCreated;
    this.dateSubmitted = dateSubmitted;
    this.clientIpAddress = clientIpAddress;
    this.status = status;
  }
}

export default Object.freeze(Endorsement);
